<template>
    <div>
        <b-row class="match-height">
            <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical color="danger" icon="CpuIcon" statistic="24" statistic-title="GDPR词条数" />
            </b-col>
            <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical icon="MapIcon" statistic="0" statistic-title="CCPA词条数" color="info" />
            </b-col>
            <b-col xl="2" md="4" sm="6">
                <statistic-card-vertical icon="BookIcon" statistic="0" statistic-title="PIPL词条数" />
            </b-col>
        </b-row>
        <b-card>
            <!-- search & new -->
            <b-row>
                <b-col cols="3">
                    <b-form-select placeholder="数据分类" v-model="query_catalog" :options="catalogOptions" />
                </b-col>
                <b-col cols="3">
                    <b-form-select placeholder="数据分级" v-model="query_level" :options="levelOptions" />
                </b-col>
                <b-col cols="3">
                    <b-input-group>
                        <b-form-input type="text" v-model="query_keyword" placeholder="名称、描述" @keypress.enter="doSearch" />
                        <b-input-group-append is-text>
                            <feather-icon icon="SearchIcon" class="cursor-pointer" @click="doSearch" />
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
                <b-col cols="3" class="text-right">
                    <b-button variant="primary" @click="doSearch">
                        <feather-icon icon="RefreshIcon" class="mr-50" />
                        <span class="align-middle">刷新</span>
                    </b-button>
                    <b-button variant="primary" @click="showEditDlg()" class="ml-1">
                        <feather-icon class="mr-50" icon="PlusIcon" />
                        <span class="align-middle">导入</span>
                    </b-button>
                    <b-button variant="primary" @click="showEditDlg()" class="ml-1">
                        <feather-icon class="mr-50" icon="PlusIcon" />
                        <span class="align-middle">新增</span>
                    </b-button>
                </b-col>
            </b-row>
            <!-- list -->
            <b-row>
                <!--data list--->
                <b-col cols="12">
                    <b-table ref="listTable" :items="listTableItems" :fields="listTableFields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" striped hover responsive :per-page="listTablePerPage" :current-page="listTableCurrentPage" class="mt-1" show-empty empty-text="没有找到符合要求的数据" @row-clicked="showEditDlg">
                        
                        <template #cell(actions)="data">
                            <div class="text-right text-nowrap">
                                <b-button variant="flat-success" class="btn-icon" @click="viewItem(data)">
                                    <feather-icon icon="EyeIcon" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </b-col>
                <!--pagination--->
                <b-col cols="12">
                    <b-pagination v-model="listTableCurrentPage" :total-rows="listTableTotalRows" :per-page="listTablePerPage" align="center" size="sm" class="my-0" />
                </b-col>
            </b-row>
            <!-- edit -->
            <DataDictionaryEdit :item="currentItem" :visible="showEdit" @hidden="onEditHidden" />
        </b-card>
        <!-- edit -->
        <b-sidebar id="sidebar-edit" bg-variant="white" width="35%" right backdrop shadow :title="currentItem.fieldNameC" v-if="currentItem" v-model="showEdit">
            <data-dictionary-edit :obj="currentItem" :toSave="toSave" @close="showEdit = false" @save="onSave" />
            <template #footer>
                <div class="p-2 border-top text-right">
                    <b-button variant="primary" class="mr-1" @click="toSave = true">保存</b-button>
                    <b-button variant="secondary" @click="showEdit = false;toSave = false;">取消</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>
<script>
import {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    BMedia,
    BSidebar,
    VBToggle,
} from "bootstrap-vue";

import DataDictionaryEdit from "./DataDictionaryEdit.vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";


export default {
  name: "DataDictionary",
    components: {
        BRow,
        BCol,
        BCard,
        BCardText,
        BFormSelect,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BTable,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInvalidFeedback,
        BAvatar,
        BFormCheckbox,
        BMedia,
        StatisticCardVertical,
        BSidebar,
        VBToggle,
        DataDictionaryEdit,
    },

    data() {
        return {
            queryData: null,
            // select options
            catalogOptions: [
                { value: "", text: "数据分类" },
                "个人基本资料",
                "个人身份信息",
                "网络身份标识信息",
                "个人教育工作信息",
                "个人财产信息",
                "个人通信信息",
                "联系人信息",
                "个人上网记录",
                "个人常用设备信息",
                "个人位置信息",
                "个人信息中得到的推论（用户画像）",
                "个人健康生理信息",
                "个人生物识别信息",
                "其他特殊类型信息",
                "未满16周岁人信息",
            ],
            levelOptions: [
                { value: "", text: "数据分级" },
                "一般个人数据",
                "特殊个人数据",
                "敏感个人信息",
                "其他",
            ],

            // query
            query_catalog: "",
            query_level: "",
            query_compliance: "",
            query_keyword: "",

            // list table
            sortBy: "index",
            sortDesc: false,
            listTableFields: [
                { key: "index", label: "序号" },
                { key: "definition", label: "名称" },
                { key: "description", label: "描述" },
                //{ key: "note", label: "备注" },
                { key: "actions", label: "操作" },
            ],
            listTablePerPage: 10,
            listTablePageOptions: [10, 15, 30],
            listTableTotalRows: 10,
            listTableCurrentPage: 1,

            // edit
            currentItem: null,
            baseInfoVisible: true,
            adminUser: null,
            showEdit: false,

            selected: [],

            assetNew: {
                id: 0,
                eid: 0,
                index: "",
                definition: "",
                description: "",
                note: "",
                status: 1,
            },
            complianceArray: [], // [] <=>","
            attachmentArray: [], // [] <=>","

            toSave: false,
        };
    },

    computed: {
        listTableItems() {
            return this.queryData ? this.queryData : [];
        },
    },

    mounted() {
        this.doSearch();
    },

    methods: {
        doSearch() {
            // debugger
            this.$http
                .get("/datadictionarys").then((res) => {
                    this.queryData = res.data.data.list;
                    this.listTableTotalRows = res.data.data.total;
                });
        },

        onEditHidden() {
            this.showEdit = false;
        },

        //
        editItem(data) {
            this.currentItem = JSON.parse(JSON.stringify(data));
            this.showEdit = true;
        },

        preview(item) {
            this.$router.push(`/datadictionarys/${item.id}`);
            //this.viewItem = item;
            //this.showView = true;
        },
        showEditDlg(item) {
            this.currentItem = item ? item : this.assetNew;
            this.showEdit = true;
        },
        onSave(newObj) {
            if (newObj.id) {
                this.$http
                    .put("/datadictionarys/" + newObj.id, newObj)
                    .then((res) => {
                        this.doSearch();
                        this.showEdit = false;
                    });
            } else {
                this.$http.post("/datadictionarys", newObj).then((res) => {
                    this.doSearch();
                    this.showEdit = false;
                });
            }
        },

        successed(msg) {
            this.$swal({
                title: "成功",
                text: msg,
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "确认",
                customClass: {
                    confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    // this.backToList();
                }
            });
        },

        onEditHidden() {
            this.showEdit = false;
        },
    },
};
</script>