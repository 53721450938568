<template>
  <div v-if="fields" class="ml-2 mr-1">
      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 定义：</template>
          <b-form-input v-model="fields.definition" placeholder="请输入词条名" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 描述：</template>
          <b-form-input v-model="fields.description" placeholder="请输入描述" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group>
          <template #label><span class="text-danger">*</span> 备注：</template>
          <b-form-input v-model="fields.note" placeholder="请输入备注" />
        </b-form-group>
      </b-col>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BLink,
  BProgress,
} from "bootstrap-vue";

import { mapState } from "vuex";

export default {
  name: "DataDictionaryEdit",

  props: ["obj", "toSave"],

  components: {
    BCard,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BRow,
    BLink,
    BProgress,
  },

  data() {
    return {
      fields: null,

      aryCompliance: [],
      attachmentArray: [],
      htmlContent: null,
      toPDFProgress: 0,

      quillEditorOption: { theme: "bubble", placeholder: "在这里输入内容" },
    };
  },

  computed: {
    ...mapState({
     
    }),
  },

  mounted() {
    this.cloneObject();
  },

  watch: {
    obj: {
      handler(val) {
        this.cloneObject();
      },
      deep: true,
    },

    toSave: {
      handler(val) {
        console.log("toSave", val);
        if (val) {
          // debugger
          this.$emit("save", this.fields);
        }
      },
      deep: true,
    },
  },

  methods: {
    cloneObject() {
      this.fields = JSON.parse(JSON.stringify(this.obj));
    },

    loadFileContent() {

    },
    
  },
};
</script>

<style>
</style>